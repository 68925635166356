import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

// Utils
import { atLeast, Color, responsive, rem } from "../../utils/style";
import NameMap from "../../utils/nameMap";
import { getProductIdForSku } from "../../utils/planToProduct";
import metrics from "../../utils/metrics";
import { orderArray } from "../../utils/order";

// Components
import PageSEO from "../../components/seo/Page";
import ReviewsHeader from "../../components/reviews/ReviewsHeader";
import Reviews from "../../components/product/Reviews";
import InstagramShowcase from "../../components/InstagramShowcase";
import Experts from "../../components/product/Experts";
import Press from "../../components/home/Press";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import CircleNew from "../../components/home/Circle.New";
import PageHero from "../../components/PageHero";

import Text from "../../components/Text";

import StickyProductNav from "../../components/global/StickyProductNav";

import { connect } from "react-redux";
import productSelectors from "../../store/product/selectors";

// Styled Elements
const CategoryCardsWrapper = styled.div`
  position: relative;
  overflow: hidden;

  h3 {
    font-size: ${rem(24)};
    letter-spacing: -0.28px;
    line-height: ${rem(34)};
    text-align: center;
    margin-bottom: 24px;

    ${responsive.md`
      font-size: ${rem(40)};
      letter-spacing: -1.17px;
      line-height: ${rem(54)};
      margin-bottom: 56px;
    `}
  }
`;

const OrderedCategoryIds = [
  "44TmdusDzEH95uGu0URjIr", // Women
  "71526baqpQqVsScHBZpIEl", // Men
  "16dqMm2vfVsgy539guPkkE", // Kids & Teens
];

export class ReviewsPage extends React.Component {
  constructor(props) {
    super(props);

    const {
      pageTitle,
      pageDescription,
    } = props.data.allContentfulReviewsPage.edges[0].node;

    const categories = this._getOrderedCategories(
      props.data.allContentfulProductCategory.nodes,
    );

    let productsArray = [];
    this.categoryMap = [];

    categories.forEach(category => {
      const products = this.generateProductsFromCategory(category);
      if (!products.length) return;

      productsArray = productsArray.concat(products);

      this.categoryMap.push({
        name: category.shortName,
        products: products,
      });
    });

    this.state = {
      products: productsArray,
      selectedProductIndex: 0,
      seo: {
        pagePath: "reviews",
        title: pageTitle,
        description: pageDescription,
      },
    };

    this.setAtLeastTablet = this.setAtLeastTablet.bind(this);
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Product Reviews",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: false,
    });

    window.addEventListener("resize", this.setAtLeastTablet);
    this.setAtLeastTablet();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setAtLeastTablet);
  }

  _getOrderedCategories(allCategoryNodes) {
    return orderArray(allCategoryNodes, OrderedCategoryIds, "contentful_id");
  }

  generateProductsFromCategory(category) {
    return category.products.reduce((productArray, product) => {
      const id = getProductIdForSku(product.sku);
      const productReviews = this.props.data.allProductReview.nodes.filter(
        review => review.product_id === id,
      );

      // Only include reviewed products
      if (productReviews.length > 0) {
        productArray.push({
          ...product,
          id: id,
          categoryName: category.categoryName,
          reviews: productReviews,
          simpleName: NameMap(product.name).plain,
          productUrl: `${process.env.GATSBY_URL}/products/${product.slug}`,
        });
      }
      return productArray;
    }, []);
  }

  changeActiveProduct(index) {
    this.setState({
      selectedProductIndex: index,
    });

    this._trackActiveProductClick(index);
  }

  _trackActiveProductClick(index) {
    let event = {
      option: this.state.products[index].simpleName,
      nonInteraction: false,
    };

    metrics.track("Product Reviews Selected", event);
  }

  setAtLeastTablet() {
    this.setState({
      atLeastTablet: atLeast.tablet(),
    });
  }

  render() {
    let { products, selectedProductIndex } = this.state;
    const { reviewRatingsMap, reviewCountsMap } = this.props;
    let activeProduct = products[selectedProductIndex];

    let productRating = {
      productId: activeProduct.id,
      productUrl: activeProduct.productUrl,
      productName: activeProduct.simpleName,
      reviewScore: reviewRatingsMap[activeProduct.id],
      reviewCount: reviewCountsMap[activeProduct.id],
    };
    let page = this.props.data.allContentfulReviewsPage.edges[0].node;
    let {
      experts,
      expertsTitle,
      expertsSubhead,
      instagramImages,
      heroTitle,
      heroSubhead,
      press,
    } = page;

    const { heroImageDesktop, heroImageMobile } = this.props.data;

    let heroContent = {
      products,
      heroTitle,
      heroSubhead,
      heroImageDesktop,
      heroImageMobile,
    };

    return (
      <>
        <PageSEO {...this.state.seo} />
        <PageHero
          backgroundColor={Color.ritualYellow}
          imageMobile={heroImageMobile}
          imageDesktop={heroImageDesktop}
          imageObjectPosition="0% 0%"
          className="height-fit"
        >
          <ReviewsHeader {...heroContent} />
        </PageHero>

        <Reviews
          key={activeProduct.id}
          reviews={activeProduct.reviews}
          products={products}
          categoryMap={this.categoryMap}
          selectedProductIndex={selectedProductIndex}
          onProductSelect={this.changeActiveProduct.bind(this)}
          bottomPadding={false}
          {...productRating}
        />

        <InstagramShowcase images={instagramImages} backgroundColor="#FFFFFF">
          <CircleNew className="bottom left" />
        </InstagramShowcase>

        <Experts
          arrowsEnabled={true}
          expertsTitle={expertsTitle}
          expertsSubhead={expertsSubhead}
          experts={experts}
        >
          <CircleNew className="top left experts" />
        </Experts>

        <Press quotes={press} background={"#e9eef18f"} />

        <CategoryCardsWrapper className="mt-7 mt-md-9 mb-7 mb-md-9">
          <h3>
            <Text
              id="category-card.section.heading"
              defaultMessage="Find Your Ritual"
            />
          </h3>
          <ProductCategoryCards />
        </CategoryCardsWrapper>

        <StickyProductNav
          sku={activeProduct.sku}
          ctaLocation={"PDP"}
          scrollOffset={150}
          showLearnMore={true}
          showTopBar={true}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  reviewCountsMap: productSelectors.reviewCountsMap(state),
  reviewRatingsMap: productSelectors.reviewRatingsMap(state),
});

export default connect(mapStateToProps)(ReviewsPage);

export const pageQuery = graphql`
  query reviewsPageQuery($locale: String!) {
    allContentfulReviewsPage(
      filter: {
        node_locale: { eq: $locale }
        contentful_id: { eq: "1gmebXPSIMeyUYsw02qi2o" }
      }
    ) {
      edges {
        node {
          pageTitle
          pageDescription
          heroTitle
          heroSubhead
          expertsTitle
          expertsSubhead
          experts {
            name
            title
            image {
              fluid(maxWidth: 80, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          products {
            ...ProductCardFragment
          }
          instagramImages {
            title
            description
            fixed(width: 270, height: 270, quality: 90) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
          press {
            author
            icon
            link
          }
        }
      }
    }
    allContentfulProductCategory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        categoryName
        shortName
        id
        products {
          id
          name {
            name
          }
          slug
          summary
          sku
          stockStatus
        }
      }
    }
    allProductReview {
      nodes {
        id
        product_id
        score
        votes_up
        votes_down
        content
        title
        sentiment
        created_at
        verified_buyer
        sticker
        age_range
        subscription_age
        would_recommend
        comment {
          id
          content
          created_at
        }
        user {
          user_id
          display_name
          social_image
        }
      }
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5q4KEScoheALXp9aG1hu1i" }
    ) {
      description
      id
      fluid(maxWidth: 1920, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2O0LcQDCSvRkyDmf4jTJuy" }
    ) {
      description
      id
      fluid(maxWidth: 912, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5q4KEScoheALXp9aG1hu1i" }
    ) {
      description
      id
      fluid(maxWidth: 1920, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2O0LcQDCSvRkyDmf4jTJuy" }
    ) {
      description
      id
      fluid(maxWidth: 912, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
